import {ProcessingNodesSelection, SoundOutputSelection} from "../types";
import Constants from "../constants";

function makeDistortionCurve(amount: number = 50) {
    var k = amount,
        n_samples = 44100,
        curve = new Float32Array(n_samples),
        deg = Math.PI / 180,
        i = 0,
        x;
    for (; i < n_samples; ++i) {
        x = i * 2 / n_samples - 1;
        curve[i] = (3 + k) * x * 20 * deg / (Math.PI + k * Math.abs(x));
    }
    return curve;
}

export class AudioChain {
    public analyser: AnalyserNode;
    public audioContext: AudioContext;
    private audioIn?: MediaStream;
    private sourceNode?: MediaStreamAudioSourceNode;
    private distortion: WaveShaperNode;
    private biquadFilter: BiquadFilterNode;
    private gainNode: GainNode;
    private delayNode: DelayNode;

    constructor(fftSize: number) {
        this.audioContext = new window.AudioContext();

        // https://developer.mozilla.org/en-US/docs/Web/API/ConvolverNode

        // this.processingEntry = this.audioContext.cre
        this.distortion = this.audioContext.createWaveShaper();
        this.biquadFilter = this.audioContext.createBiquadFilter();
        this.gainNode = this.audioContext.createGain();
        this.delayNode = this.audioContext.createDelay();

        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = fftSize;

        this.distortion.curve = makeDistortionCurve();
        this.gainNode.gain.value = 2;
        this.delayNode.delayTime.value = 0.7;

        // These stay chained up, but the question is whether we connect to distortion and from gain
        // or just directly from source into analyser
        this.distortion.connect(this.biquadFilter);
        this.biquadFilter.connect(this.delayNode);
        this.delayNode.connect(this.gainNode);
    }

    get fftSize(): number {
        return this.analyser.fftSize;
    }

    // set fftSize(value: number) {
    //     this.analyser.fftSize = value;
    // }

    private _useProcessingNodes: ProcessingNodesSelection = Constants.processingNodesSelectionDefault;

    get useProcessingNodes(): ProcessingNodesSelection {
        return this._useProcessingNodes;
    }

    set useProcessingNodes(value: ProcessingNodesSelection) {
        if (this._useProcessingNodes === value) return;
        this.connectProcessingNodes(value);
        this._useProcessingNodes = value;
    }

    private _outputConnected: SoundOutputSelection = Constants.soundoutputSelectionDefault;

    get outputConnected(): SoundOutputSelection {
        return this._outputConnected;
    }

    set outputConnected(value: SoundOutputSelection) {
        if (this._outputConnected === value) return;
        this.connectOutputNode(value);
        this._outputConnected = value;
    }

    public get connected(): boolean {
        return !!this.audioIn;
    }

    public connect(audioIn: MediaStream) {
        this.audioIn = audioIn;
        this.sourceNode = this.audioContext.createMediaStreamSource(audioIn);

        this.connectProcessingNodes(this._useProcessingNodes);
        this.connectOutputNode(this._outputConnected);

        this.show()
    }

    public disconnectAll() {
        this.audioIn?.getTracks().forEach(track => track.stop());
        this.audioIn = undefined;
        this.sourceNode = undefined;
    }

    public show() {
        console.log(`sourceNode: ${this.sourceNode?.numberOfInputs} / ${this.sourceNode?.numberOfOutputs}`);
        console.log(`distortion: ${this.distortion.numberOfInputs} / ${this.distortion.numberOfOutputs}`);
        console.log(`biquadFilter: ${this.biquadFilter.numberOfInputs} / ${this.biquadFilter.numberOfOutputs}`);
        console.log(`delayNode: ${this.delayNode.numberOfInputs} / ${this.delayNode.numberOfOutputs}`);
        console.log(`gainNode: ${this.gainNode.numberOfInputs} / ${this.gainNode.numberOfOutputs}`);
        console.log(`analyser: ${this.analyser.numberOfInputs} / ${this.analyser.numberOfOutputs}`);
        console.log(`Input info: channelCount: ${this.sourceNode?.channelCount} channelCountMode: ${this.sourceNode?.channelCountMode} channelInterpretation: ${this.sourceNode?.channelInterpretation}`);
    }

    private connectProcessingNodes(value: ProcessingNodesSelection) {
        switch (value) {
            case "all":
                if (this.sourceNode?.numberOfOutputs === 1) this.sourceNode!.disconnect(0);
                console.log('Connecting source -> distortion, gain -> analyser');
                this.sourceNode?.connect(this.distortion);
                this.gainNode.connect(this.analyser);
                break;
            case "none":
                if (this.sourceNode?.numberOfOutputs === 1) this.sourceNode!.disconnect(0);
                if (this.gainNode.numberOfOutputs === 1) {
                    this.gainNode.disconnect(0);
                    console.log(`disconnected gain: ${this.gainNode.numberOfInputs} / ${this.gainNode.numberOfOutputs}`)
                }
                console.log('Connecting source -> analyser');
                this.sourceNode?.connect(this.analyser);
        }
    }

    private connectOutputNode(value: SoundOutputSelection) {
        switch (value) {
            case "play-audio":
                console.log('Hooking up destination')
                this.analyser.connect(this.audioContext.destination);
                break;
            case "none":
                if (this.analyser.numberOfOutputs === 1) {
                    console.log('Disconnecting destination')
                    this.analyser.disconnect(0);
                }
        }
    }

}

