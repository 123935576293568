import {SeriesStatistics} from "./utils";
import {FrameStatistics, ProcessingNodesSelection, SoundOutputSelection} from "./types";

class Constants {

    static readonly soundoutputSelectionDefault: SoundOutputSelection = 'none';
    static readonly processingNodesSelectionDefault: ProcessingNodesSelection = 'none';
    static readonly logDefaultFftSize = 11;
    static readonly defaultFftSize: number = 2 ** Constants.logDefaultFftSize; // Default that works OK is 2**11

    static getEmptyFrameStatistics(): FrameStatistics {
        return {
            frequencyDomainStats: new SeriesStatistics(),
            timeDomainStats: new SeriesStatistics(),
            sampleCharacteristics: {},
        }
    }

}

export default Constants;