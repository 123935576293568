import Denque from "denque";
import {OneDSeriesType, SampleCharacteristics} from "../types";


export class SampleCharacteristicsSeries {
    sampleCharacteristicsSeries: Denque<SampleCharacteristics>;
    loudnessSeries: OneDSeriesType;
    amdfSeries: OneDSeriesType;
    yinSeries: OneDSeriesType;
    dynamicWaveletSeries: OneDSeriesType;
    acf2PlusSeries: OneDSeriesType;
    macleodSeries: OneDSeriesType;
    fftPeakFrequencySeries: OneDSeriesType;
    avgOfDefinedPitches: OneDSeriesType;

    constructor(length: number) {
        const options = {capacity: length};
        const initSeries = () => new Denque([], options);

        this.sampleCharacteristicsSeries = initSeries();

        this.loudnessSeries = initSeries();
        this.amdfSeries = initSeries();
        this.yinSeries = initSeries();
        this.dynamicWaveletSeries = initSeries();
        this.acf2PlusSeries = initSeries();
        this.macleodSeries = initSeries();
        this.fftPeakFrequencySeries = initSeries();
        this.avgOfDefinedPitches = initSeries();
    }

    private static getAvg(entry: SampleCharacteristics) {
        let definedCount = 0;
        let tot = 0;
        const evaluate = (p: number | undefined) => {
            if (p !== undefined && SampleCharacteristicsSeries.inVocalRange(p)) {
                definedCount += 1;
                tot += p;
            }
        }
        evaluate(entry.amdf);
        evaluate(entry.yin);
        evaluate(entry.dynamicWavelet);
        evaluate(entry.acf2Plus);
        evaluate(entry.macleod?.freq);
        evaluate(entry.fftPeakFrequency);
        if (definedCount < 2) return -999
        return tot / definedCount;
    }

    private static inVocalRange(frequency: number | undefined) {
        return (frequency && 50 < frequency && frequency < 1000);
    }

    private static addEntry = (series: OneDSeriesType, entry: number | undefined) => {
        if (entry && SampleCharacteristicsSeries.inVocalRange(entry)) {
            series.push(entry);
        } else {
            series.push(-999);
        }
    }

    public push(entry: SampleCharacteristics) {
        this.loudnessSeries.push(entry.loudness);

        this.sampleCharacteristicsSeries.push(entry);
        SampleCharacteristicsSeries.addEntry(this.amdfSeries, entry.amdf);
        SampleCharacteristicsSeries.addEntry(this.yinSeries, entry.yin);
        SampleCharacteristicsSeries.addEntry(this.dynamicWaveletSeries, entry.dynamicWavelet);
        SampleCharacteristicsSeries.addEntry(this.acf2PlusSeries, entry.acf2Plus);
        SampleCharacteristicsSeries.addEntry(this.macleodSeries, entry.macleod?.freq);
        SampleCharacteristicsSeries.addEntry(this.fftPeakFrequencySeries, entry.fftPeakFrequency);
        SampleCharacteristicsSeries.addEntry(this.avgOfDefinedPitches, SampleCharacteristicsSeries.getAvg(entry));

        // this.amdfSeries.push(entry.amdf || -999);
        // this.yinSeries.push(entry.yin || -999);
        // this.dynamicWaveletSeries.push(entry.dynamicWavelet || -999);
        // this.acf2PlusSeries.push(entry.acf2Plus || -999);
        // this.macleodSeries.push(entry.macleod?.freq || -999);
        // this.fftPeakFrequencySeries.push(entry.fftPeakFrequency || -999);

        // this.avgOfDefinedPitches.push(SampleCharacteristicsSeries.getAvg(entry));
    }
}