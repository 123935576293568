import React, {Component} from 'react';
import AudioVisualiser from './audioVisualiser';
import {AudioChain} from "./audioChain";
import JumpGame, {course1} from "../game/jumpGame";
import GameVis from "../game/gameVis";
import AudioProcessor, {AudioProcessorConfig} from "./AudioProcessor";
import {SampleChunk} from "../types";

// From https://github.com/philnash/react-web-audio/tree/master/src

// Analyser see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode
// Demo at https://mdn.github.io/voice-change-o-matic/

// Good looking pitch paper: https://courses.engr.illinois.edu/ece420/sp2019/4_PitchDetermination.pdf

// https://github.com/peterkhayes/pitchfinder !!!

// online tone generator: https://www.szynalski.com/tone-generator/


interface AudioAnalyserProps {
    audioChain: AudioChain,
}

interface AudioAnalyserState {
    // timeDomainData: Uint8Array,
    // frequencyDomainData: Uint8Array,
    timeDomainFloatData: Float32Array,
    frequencyDomainFloatData: Float32Array,
}

class AudioAnalyser extends Component<AudioAnalyserProps, AudioAnalyserState> {
    // private timeDomainData: Uint8Array;
    // private frequencyDomainData: Uint8Array;
    private timeDomainFloatData: Float32Array;
    private frequencyDomainFloatData: Float32Array;
    private rafId: number = 0;
    private audioProcessor: AudioProcessor;

    private game: JumpGame;

    constructor(props: AudioAnalyserProps) {
        super(props);
        this.state = {
            // timeDomainData: new Uint8Array(0),
            // frequencyDomainData: new Uint8Array(0),
            timeDomainFloatData: new Float32Array(0),
            frequencyDomainFloatData: new Float32Array(0),
        };

        // This controls how many buckets the frequency analysis is done in.
        // this.analyser.fftSize = 64;

        // this.timeDomainData = new Uint8Array(this.props.audioChain.analyser.frequencyBinCount);
        // this.frequencyDomainData = new Uint8Array(this.props.audioChain.analyser.frequencyBinCount);
        this.timeDomainFloatData = new Float32Array(this.props.audioChain.analyser.fftSize);
        this.frequencyDomainFloatData = new Float32Array(this.props.audioChain.analyser.frequencyBinCount);

        const apConfig: AudioProcessorConfig = {
            sampleRate: this.props.audioChain.audioContext.sampleRate,
            fftSize: this.props.audioChain.fftSize,
            baseLatency: this.props.audioChain.audioContext.baseLatency,
        }
        this.audioProcessor = new AudioProcessor(apConfig);

        this.game = new JumpGame(course1);
    }

    componentDidMount() {
        // this.audioContext = new (window.AudioContext ||
        //     window.webkitAudioContext)();
        this.rafId = requestAnimationFrame(this.tick);
    }

    tick = () => {
        // this.props.audioChain.analyser?.getByteTimeDomainData(this.timeDomainData);
        // this.props.audioChain.analyser?.getByteFrequencyData(this.frequencyDomainData);


        this.props.audioChain.analyser?.getFloatTimeDomainData(this.timeDomainFloatData);
        this.props.audioChain.analyser?.getFloatFrequencyData(this.frequencyDomainFloatData);

        const sampleChunk: SampleChunk = {
            timeDomainData: this.timeDomainFloatData,
            frequencyDomainData: this.frequencyDomainFloatData,
        }

        this.audioProcessor.processChunk(sampleChunk);


        // const r = Pitchfinder.frequencies([this.amdf,this.yin, this.dynamicWavelet, this.acf2Plus], this.timeDomainFloatData, {...defaultConfig} );
        // console.log(r)

        // console.log(this.sampleCharacteristicsSeries.length)

        // const decayedStats: FrameStatistics = {
        //     timeDomainStats: oldFrameStats.timeDomainStats.decayTowards(newFrameStats.timeDomainStats),
        //     frequencyDomainStats: oldFrameStats.frequencyDomainStats.decayTowards(newFrameStats.frequencyDomainStats),
        // }

        // const newFrameStats = AudioAnalyser.processChunk(this.timeDomainData, this.frequencyDomainData);

        this.setState({
            // timeDomainData: this.timeDomainData,
            // frequencyDomainData: this.frequencyDomainData,
            timeDomainFloatData: this.timeDomainFloatData,
            frequencyDomainFloatData: this.frequencyDomainFloatData,
        });
        this.rafId = requestAnimationFrame(this.tick);

        this.game.tick();

    }

    componentWillUnmount() {
        cancelAnimationFrame(this.rafId);
    }

    render() {
        return (this.audioProcessor.chunksProcessed ?
            <AudioVisualiser
                audioProcessor={this.audioProcessor}
                // frameStats={this.state.frameStats}
                // frameStatsHistory={this.frameStatsHistory}
                // pitchGuesses={this.state.pitchGuesses}
                // sampleCharacteristicsSeries={this.state.sampleCharacteristicsSeries}
                // sampleCharacteristicsSeries={this.state.sampleCharacteristicsSeries}
                // audioChain={this.props.audioChain}
                height={window.innerHeight * .6}
                width={window.innerWidth}
            /> :
            <></>);
        return <GameVis
            game={this.game}
            height={window.innerHeight * .2}
            width={window.innerWidth}
        />;
    }

}

export default AudioAnalyser;