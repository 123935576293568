export type NumberArray = Uint8Array | Float32Array;

export class SeriesStatistics {
    public count: number = 0;
    public sum: number = 0;
    public sumOfMagnitudes: number = 0;
    public mean: number = 0;
    public m2: number = 0;
    public variance: number = 0;
    public sampleVariance: number = 0;
    public meanMagnitude: number = 0;
    public stdev: number = 0;
    public min: number = Number.MAX_VALUE;
    public minidx: number = 0;
    public max: number = Number.NEGATIVE_INFINITY;
    public maxidx: number = 0;
    public sourceData?: NumberArray;

    public static fromNumberArray(series: NumberArray) {
        const ret = new SeriesStatistics();
        ret.sourceData = series;

        // Adapted from https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance
        for (let i = 0; i < series.length; i += 1) {
            const newValue = series[i];
            // Check min and max.
            if (newValue < ret.min) {
                ret.min = newValue;
                ret.minidx = i;
            }
            if (newValue > ret.max) {
                ret.max = newValue;
                ret.maxidx = i;
            }
            ret.count += 1;
            ret.sum += newValue;
            ret.sumOfMagnitudes += Math.abs(newValue);
            const delta = newValue - ret.mean;
            ret.mean += delta / ret.count;
            const delta2 = newValue - ret.mean;
            ret.m2 += delta * delta2
            //     # Retrieve the mean, variance and sample variance from an aggregate
            //     def finalize(existingAggregate):
            //     (count, mean, M2) = existingAggregate
            //     if count < 2:
            //     return float("nan")
            // else:
            //     (mean, variance, sampleVariance) = (mean, M2 / count, M2 / (count - 1))
            //     return (mean, variance, sampleVariance)
        }
        ret.variance = ret.m2 / ret.count;
        ret.sampleVariance = ret.m2 / (ret.count - 1);
        ret.stdev = Math.sqrt(ret.variance);
        ret.meanMagnitude = ret.sumOfMagnitudes / ret.count;
        return ret;
    }

    public toString(): string {
        return [
            `count: ${this.count}`,
            `sumOfMagnitudes: ${this.sumOfMagnitudes.toFixed(3)}`,
            `meanMagnitude: ${this.meanMagnitude.toFixed(4)}`,
            `variance: ${this.variance.toFixed(3)}`,
            `sampleVariance: ${this.sampleVariance.toFixed(3)}`,
            `stdev: ${this.stdev.toPrecision(2)}`,
            `min: ${this.min.toPrecision(3)} at ${this.minidx}`,
            `max: ${this.max.toPrecision(3)} at ${this.maxidx}`,
        ].join('\n');
    }

    public decayTowards(newStats: SeriesStatistics) {
        const ret = new SeriesStatistics();

        const halflife = 450;
        const decayProportion = Math.pow(.5, 1 / halflife);
        // For halflife = 20, this is 0.9659363289248455
        // console.log(decayProportion)

        ret.count = Math.max(newStats.count, this.count * decayProportion);
        ret.mean = Math.max(newStats.mean, this.mean * decayProportion);
        ret.min = Math.min(newStats.min, this.min * decayProportion);
        ret.max = Math.max(newStats.max, this.max * decayProportion);
        ret.sampleVariance = Math.max(newStats.sampleVariance, this.sampleVariance * decayProportion);
        ret.stdev = Math.max(newStats.stdev, this.stdev * decayProportion);
        ret.variance = Math.max(newStats.variance, this.variance * decayProportion);
        return ret;
    }

    /**
     * Copy out all of the values in this, but don't retain a reference to the original source data.
     */
    private copy() {
        const ret = new SeriesStatistics();
        ret.max = this.max;
        ret.count = this.count;
        ret.mean = this.mean;
        ret.m2 = this.m2;
        ret.min = this.min;
        ret.minidx = this.minidx;
        ret.max = this.max;
        ret.maxidx = this.maxidx;
        ret.sampleVariance = this.sampleVariance;
        ret.stdev = this.stdev;
        ret.variance = this.variance;
        return ret;
    }


}

// Borrowed from https://github.com/cwilso/PitchDetect/blob/master/js/pitchdetect.js
const noteStrings = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

export function linearizePitchValue(frequency: number) {
    var noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
    return noteNum;
}

export function noteFromPitch(frequency: number): string | undefined {
    var noteNum = linearizePitchValue(frequency);
    const note = Math.round(noteNum) + 69;
    const index = note % 12;
    return noteStrings[index];
}


export function fftIndexToFrequency(fftSize: number, sampleRate: number, index: number) {
    const bins = fftSize;
    // console.log(bins)
    // console.log(sampleRate)
    return (index) * sampleRate / bins;
}
