import Denque from "denque";
import {FrameStatistics, SampleCharacteristics, SampleChunk} from "../types";
import {fftIndexToFrequency, SeriesStatistics} from "../utils";
import {PitchDetector, ProbabalisticPitchDetector} from "pitchfinder/lib/detectors/types";
import Pitchfinder from "pitchfinder";
import {SampleCharacteristicsSeries} from "./sampleCharacteristicsSeries";

class CharacteristicsFinder {
    private fftSize: number;
    private sampleRate: number;
    private amdf: PitchDetector;
    private yin: PitchDetector;
    private dynamicWavelet: PitchDetector;
    private acf2Plus: PitchDetector;
    private macleod: ProbabalisticPitchDetector;

    constructor(config: AudioProcessorConfig) {
        this.fftSize = config.fftSize;
        this.sampleRate = config.sampleRate;

        const defaultConfig = {sampleRate: this.sampleRate};

        this.amdf = Pitchfinder.AMDF({...defaultConfig, minFrequency: 20, maxFrequency: 1000});
        this.yin = Pitchfinder.YIN({...defaultConfig});
        this.dynamicWavelet = Pitchfinder.DynamicWavelet({...defaultConfig});
        this.acf2Plus = Pitchfinder.ACF2PLUS({...defaultConfig});
        this.macleod = Pitchfinder.Macleod({...defaultConfig});

    }

    runOnSample(sample: SampleChunk, frequencyDomainStats: SeriesStatistics): SampleCharacteristics {
        const sampleCharacteristics: SampleCharacteristics = {
            loudness: frequencyDomainStats.max,

            // amdf: this.amdf(sample.timeDomainData) || undefined,
            yin: this.yin(sample.timeDomainData) || undefined,
            dynamicWavelet: this.dynamicWavelet(sample.timeDomainData) || undefined,
            acf2Plus: this.acf2Plus(sample.timeDomainData) || undefined,
            macleod: this.macleod(sample.timeDomainData) || undefined,
            fftPeakFrequency: fftIndexToFrequency(this.fftSize, this.sampleRate, frequencyDomainStats.maxidx),
        }
        return sampleCharacteristics;
    }

}

export interface AudioProcessorConfig {
    sampleRate: number,
    fftSize: number,
    baseLatency: number,
}

class AudioProcessor {
    private characteristicsFinder: CharacteristicsFinder;

    constructor(public readonly config: AudioProcessorConfig) {
        this.characteristicsFinder = new CharacteristicsFinder(config);
        this._frameStatsHistory = new Denque<FrameStatistics>([], {capacity: 50});
        this._sampleCharacteristicsSeries = new SampleCharacteristicsSeries(250);
        this._chunksProcessed = 0;
    }

    private _chunksProcessed: number;

    get chunksProcessed(): number {
        return this._chunksProcessed;
    }

    private _frameStatsHistory: Denque<FrameStatistics>;

    get frameStatsHistory(): Denque<FrameStatistics> {
        return this._frameStatsHistory;
    }

    private _sampleCharacteristicsSeries: SampleCharacteristicsSeries;

    get sampleCharacteristicsSeries(): SampleCharacteristicsSeries {
        return this._sampleCharacteristicsSeries;
    }

    public get latestFrameStats(): FrameStatistics {
        return this._frameStatsHistory.peekBack()!;
    }

    public processChunk(sample: SampleChunk) {
        const timeDomainStats = SeriesStatistics.fromNumberArray(sample.timeDomainData);
        const frequencyDomainStats = SeriesStatistics.fromNumberArray(sample.frequencyDomainData);
        const sampleCharacteristics = this.characteristicsFinder.runOnSample(sample, frequencyDomainStats);
        const newFrameStats = {
            timeDomainStats: timeDomainStats,
            frequencyDomainStats: frequencyDomainStats,
            sampleCharacteristics: sampleCharacteristics,
        } as FrameStatistics;

        this._frameStatsHistory.push(newFrameStats);
        this._sampleCharacteristicsSeries.push(sampleCharacteristics);

        this._chunksProcessed += 1;
    }
}

export default AudioProcessor;