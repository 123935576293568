class WorldState {
    public time: number = 0;
}

abstract class Obstruction {
    abstract inDeadZone(loc: Location): boolean;

    abstract draw(worldState: WorldState): void;
}

class ObstacleCourse {
    distance: number = 0;

    constructor(obstructions: [Obstruction, Location][]) {
    }

    public advance(distance: number) {
        this.distance += distance;
    }

}

class JumpGame {
    constructor(public obstacleCourse: ObstacleCourse) {
    }

    tick() {

    }

    public visibleObjects() {
        return [];
    }
}

class Location {
    constructor(public readonly loc: number) {
    }
}

class FlatGate extends Obstruction {
    constructor(public readonly top: number, public readonly bottom: number) {
        super();
    }

    inDeadZone(loc: Location): boolean {
        return loc.loc > this.bottom && loc.loc < this.top;
    }

    draw(worldState: WorldState): void {
    }

}

export const course1: ObstacleCourse = new ObstacleCourse(
    [
        [new FlatGate(.2, .5), new Location(10)],
        [new FlatGate(.5, 1.0), new Location(20)]
    ]);


export default JumpGame;