import React, {Component} from 'react';
import './reactWebAudioApp.css';
import {
    AudioChain
} from "./processing/audioChain";
import AudioAnalyser from "./processing/audioAnalyser";
import {ProcessingNodesSelection, SoundOutputSelection} from "./types";
import Constants from "./constants";


// From https://github.com/philnash/react-web-audio/tree/master/src


interface AppProps {
}


interface AppState {
    audioChain?: AudioChain,
    selectedSoundOutput: SoundOutputSelection,
    selectedProcessingNodes: ProcessingNodesSelection,
}

class ReactWebAudioApp extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            selectedSoundOutput: Constants.soundoutputSelectionDefault,
            selectedProcessingNodes: Constants.processingNodesSelectionDefault,
        };
    }

    private async buildAudioChain() {
        const audioChain = new AudioChain(Constants.defaultFftSize);

        await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false,
        }).then(function (audio) {
            audioChain.connect(audio);
        }).catch(function (err) {
            console.log('The following gUM error occured: ' + err);
        })

        this.setState({
            audioChain,
        });
    }

    private disconnectChain() {
        this.state.audioChain?.disconnectAll();
        this.setState({audioChain: undefined});
    }

    private toggleMicrophone = () => {
        if (this.state.audioChain?.connected) {
            this.disconnectChain();
        } else {
            this.buildAudioChain();
        }
    }

    private onSoundOutputChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value as SoundOutputSelection;
        let audioChain = this.state.audioChain;
        if (audioChain) {
            audioChain.outputConnected = newValue;
        }

        this.setState({
            selectedSoundOutput: newValue,
        });
        audioChain?.show()
    }

    private onProcessingNodesChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value as ProcessingNodesSelection;
        let audioChain = this.state.audioChain;
        if (audioChain) audioChain.useProcessingNodes = newValue;

        this.setState({
            selectedProcessingNodes: newValue,
        });
        audioChain?.show()
    }

    render() {
        return (
            <div className="App">
                <div className="audio-controls">
                    <button className={'controls-button'}
                            onClick={this.toggleMicrophone}>
                        {this.state.audioChain?.connected ? 'Stop microphone' : 'Get microphone input'}
                    </button>
                    {this.state.audioChain ?
                        <div className={'option-radios'}>
                            <div>
                                <label>
                                    <input type="radio" value="none" name="soundOutput"
                                           onChange={this.onSoundOutputChangeValue}
                                           checked={this.state.selectedSoundOutput === "none"}/>
                                    No Sound Output
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="play-audio" name="soundOutput"
                                           onChange={this.onSoundOutputChangeValue}
                                           checked={this.state.selectedSoundOutput === "play-audio"}/>
                                    Sound Output
                                </label>
                            </div>
                        </div> :
                        <></>}

                    {this.state.audioChain ?
                        <div className={'option-radios'}>
                            <div>
                                <label>
                                    <input type="radio" value="all" name="processingNodes"
                                           onChange={this.onProcessingNodesChangeValue}
                                           checked={this.state.selectedProcessingNodes === "all"}/>
                                    Include Processing Nodes
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" value="none" name="processingNodes"
                                           onChange={this.onProcessingNodesChangeValue}
                                           checked={this.state.selectedProcessingNodes === "none"}/>
                                    No Processing
                                </label>
                            </div>
                        </div>
                        :
                        <></>}
                </div>
                {this.state.audioChain ?
                    <AudioAnalyser audioChain={this.state.audioChain}/> :
                    <></>}
            </div>
        );
    }

}

export default ReactWebAudioApp;