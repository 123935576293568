import React, {Component} from 'react';
import Denque from "denque";
import {linearizePitchValue, noteFromPitch} from "../utils";
import AudioProcessor from "./AudioProcessor";

// From https://github.com/philnash/react-web-audio/tree/master/src

// Visualizer from https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode
// code here https://github.com/mdn/voice-change-o-matic/blob/gh-pages/scripts/app.js#L128-L205

interface AudioVisualiserProps {
    // frameStats: FrameStatistics,
    // frameStatsHistory: Denque<FrameStatistics>,
    // audioChain: AudioChain,
    // pitchGuesses: SampleCharacteristics,
    // sampleCharacteristicsSeries: Denque<SampleCharacteristics>,
    // sampleCharacteristicsSeries: SampleCharacteristicsSeries,

    audioProcessor: AudioProcessor,

    height: number,
    width: number,
}

interface AudioVisualiserState {
}

class AudioVisualiser extends Component<AudioVisualiserProps, AudioVisualiserState> {
    // private timeDomainCanvas: React.RefObject<HTMLCanvasElement>;
    private visualiserCanvas: React.RefObject<HTMLCanvasElement>;
    private drawCount = 0;
    private startTime = 0;

    constructor(props: AudioVisualiserProps) {
        super(props);
        // this.timeDomainCanvas = React.createRef();
        this.visualiserCanvas = React.createRef();
    }

    componentDidUpdate() {
        this.draw();
    }

    draw() {
        const canvas = this.visualiserCanvas.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (!context) {
                console.error(`Can't get canvas.getContext`);
                return;
            }
            if (this.drawCount % 1000 === 0) {
                this.startTime = performance.now();
            }
            this.drawCount += 1;

            context.clearRect(0, 0, this.props.width, this.props.height);
            this.plotFrequencyDomainHistogram(canvas);
            this.plotTimeDomainLine(canvas);
            this.plotExtremaLines(context);
            this.plotCharacteristicsSeries(canvas);
        }
    }

    private timeSeriesToY(value: number) {
        const yTop = Math.max(1.0, this.props.audioProcessor.latestFrameStats.timeDomainStats.max);
        const yBottom = Math.min(-1.0, this.props.audioProcessor.latestFrameStats.timeDomainStats.min);
        const scaleUp = 1;
        const alpha = (value * scaleUp - yBottom) / (yTop - yBottom);
        return (1 - alpha) * this.props.height;
    }

    private pitchSeriesToY(value: number) {
        const yTop = linearizePitchValue(2000);
        const yBottom = linearizePitchValue(100);
        // console.log(value)
        const alpha = (linearizePitchValue(value) - yBottom) / (yTop - yBottom);
        // console.log(alpha)
        return (1 - alpha) * this.props.height;
    }

    private frequencySeriesToY(value: number) {
        const yTop = 0;
        // const yTop =  this.props.frameStats.frequencyDomainStats.max;
        const yBottom = this.props.audioProcessor.latestFrameStats.frequencyDomainStats.min;
        // const yBottom = this.props.frameStats.frequencyDomainStats.min;
        const alpha = (value - yBottom) / (yTop - yBottom);
        return (1 - alpha);
    }

    private plotTimeDomainLine(canvas: HTMLCanvasElement) {
        let frameStats = this.props.audioProcessor.latestFrameStats;
        const sourceData = frameStats.timeDomainStats.sourceData;
        if (!sourceData || !frameStats) return;
        const height = canvas.height;
        const width = canvas.width;
        const context = canvas.getContext('2d');
        const sliceWidth = width / sourceData.length;

        // const yTop = 1.0;
        // const yTop = frameStats.frequencyDomainStats.max;
        // const yBottom = frameStats.timeDomainStats.min;
        let x = 0;
        if (context) {
            context.lineWidth = 2;
            context.strokeStyle = '#000000';

            context.beginPath();
            context.moveTo(0, height / 2);

            sourceData.forEach((value, i) => {
                const y = this.timeSeriesToY(value);
                context.lineTo(x, y);
                x += sliceWidth;
            })
            context.lineTo(x, height / 2);
            context.stroke();

        }
    }

    render() {
        const fps = Math.floor(10000 * (this.drawCount % 1000) / (performance.now() - this.startTime)) / 10;
        const struse = Math.floor(100 * fps * this.props.audioProcessor.config.fftSize / this.props.audioProcessor.config.sampleRate) / 100;
        return <div>
            <canvas ref={this.visualiserCanvas}
                    className={'visualiser-canvas'}
                    width={this.props.width}
                    height={this.props.height}/>
            <div>Draw count: {this.drawCount} {fps.toFixed(1)} FPS {struse.toFixed(2)} of audio stream used</div>
            <br/>
            <div>Audio Context: sampleRate: {this.props.audioProcessor.config.sampleRate}hz
                baseLatency: {this.props.audioProcessor.config.baseLatency}</div>
            <div>Time Series {this.props.audioProcessor.latestFrameStats.timeDomainStats.toString()}</div>
            <div>Frequency Series {this.props.audioProcessor.latestFrameStats.frequencyDomainStats.toString()}</div>
            {this.pitchResponses()}


        </div>
    }

    private plotPitchSeries(canvas: HTMLCanvasElement, seriesName: string, index: number, series: Denque<number | undefined>, linewidth: number, strokeStyle: string) {
        // console.log('plotting')
        const context = canvas.getContext('2d')!;
        const height = canvas.height;
        const width = canvas.width;
        const sampleCount = series.length;
        const sliceWidth = width / sampleCount;
        context.lineWidth = linewidth;
        context.strokeStyle = strokeStyle;

        let x = 0;
        if (context) {
            // console.log(series.length)
            context.beginPath();
            // context.moveTo(x, height / 2);
            let previousSampleGood = false;

            for (let i = 0; i < series.length; i += 1) {
                const value = series.get(i);
                const currentSampleGood = (value !== undefined && value > 1);
                if (currentSampleGood && previousSampleGood) {
                    const y = this.pitchSeriesToY(value!);
                    context.lineTo(x, y);
                } else {
                    if (currentSampleGood) {
                        const y = this.pitchSeriesToY(value!);
                        context.moveTo(x, y);
                        context.lineTo(x, y);
                    } else {
                        context.moveTo(x, 0);
                    }
                }
                previousSampleGood = currentSampleGood;
                x += sliceWidth;
            }
            // context.lineTo(x, height / 2);
            context.stroke();
        }

        context.font = `15px Arial`;
        context.fillStyle = strokeStyle;
        context.fillText(seriesName, 5, 20 + index * 20);
    }

    private static drawHorizontalLine(context: CanvasRenderingContext2D, y: number, strokeStyle: string = '#000000', linewidth = 1) {
        context.lineWidth = linewidth;
        context.strokeStyle = strokeStyle;
        context.beginPath();
        context.moveTo(0, y);
        context.lineTo(context.canvas.width, y);
        context.stroke();
    }

    private plotExtremaLines(context: CanvasRenderingContext2D) {
        let minv = Number.MAX_VALUE, maxv = Number.MIN_VALUE;
        const frameStatsHistory = this.props.audioProcessor.frameStatsHistory;
        for (let i = 0; i < frameStatsHistory.length; i += 1) {
            const frameStats = frameStatsHistory.get(i)?.timeDomainStats;
            minv = Math.min(frameStats!.min, minv);
            maxv = Math.max(frameStats!.max, maxv);
        }
        // Draw time minline
        {
            const y = this.timeSeriesToY(minv);
            AudioVisualiser.drawHorizontalLine(context, y, '#dd0000', 3);
        }

        // Draw time maxline
        {
            const y = this.timeSeriesToY(maxv);
            AudioVisualiser.drawHorizontalLine(context, y, '#00dd00', 3);
        }

        // Draw frequency maxline
        {
            const y = this.frequencySeriesToY(this.props.audioProcessor.latestFrameStats.frequencyDomainStats.max);
            AudioVisualiser.drawHorizontalLine(context, y * this.props.height, '#00ddbb');
        }
    }

    private plotFrequencyDomainHistogram(canvas: HTMLCanvasElement) {
        let frameStats = this.props.audioProcessor.latestFrameStats;
        const sourceData = frameStats.frequencyDomainStats.sourceData;
        const {fftSize, sampleRate} = this.props.audioProcessor.config
        const lastUsableIndex = 2000 * fftSize / sampleRate;

        if (!sourceData || !frameStats) return;
        const height = canvas.height;
        const width = canvas.width;
        const context = canvas.getContext('2d');
        const sliceWidth = width / Math.min(lastUsableIndex, sourceData.length);

        let x = 0;
        if (context) {

            for (let i = 0; i < lastUsableIndex; i += 1) {
                const value = sourceData[i];
                const lineLength = this.frequencySeriesToY(value);
                // console.log(lineLength)

                context.beginPath();
                if (frameStats.frequencyDomainStats.max === value || frameStats.frequencyDomainStats.min === value) {
                    context.lineWidth = 10;
                    context.strokeStyle = '#dddd00';
                } else {
                    context.lineWidth = 5;
                    context.strokeStyle = `rgb(${Math.floor((lineLength) * 256)},255,255)`;
                    // context.strokeStyle = `#54D5EE`;
                }
                // Starting at the bottom
                context.moveTo(x, height);
                context.lineTo(x, lineLength * this.props.height);
                x += sliceWidth;
                context.stroke();

            }
            // sourceData.forEach((value, i) => {
            //     context.beginPath();
            //     if (frameStats.frequencyDomainStats.max === value || frameStats.frequencyDomainStats.min === value) {
            //         context.lineWidth = 10;
            //         context.strokeStyle = '#dddd00';
            //     } else {
            //         context.lineWidth = 1;
            //         context.strokeStyle = '#dd0000';
            //     }
            //     // Starting at the bottom
            //     context.moveTo(x, height);
            //     context.lineTo(x, this.frequencySeriesToY(value));
            //     x += sliceWidth;
            //     context.stroke();
            // })

        }
    }

    private plotCharacteristicsSeries(canvas: HTMLCanvasElement) {
        const {
            loudnessSeries,
            fftPeakFrequencySeries, macleodSeries, acf2PlusSeries,
            amdfSeries, dynamicWaveletSeries, yinSeries, avgOfDefinedPitches
        } = this.props.audioProcessor.sampleCharacteristicsSeries;

        const pitchSeriesPlots: [string, Denque<number | undefined>, number, string][] = [
            ['avgOfDefined', avgOfDefinedPitches, 4, '#dd0055'],
            ['yinSeries', yinSeries, 2, '#5577aa'],
            ['macleodSeries', macleodSeries, 2, '#dd9944'],
            ['dynamicWaveletSeries', dynamicWaveletSeries, 2, '#aaddaf'],
            ['acf2PlusSeries', acf2PlusSeries, 2, 'rgba(135,125,63,0.66)'],
            ['amdfSeries', amdfSeries, 2, '#3E8AA8'],
            ['fftPeakFrequency', fftPeakFrequencySeries, 2, '#668888'],
        ]
        const g = [
            ['loudness', loudnessSeries, 6, '#2F69FF'],
        ];

        pitchSeriesPlots.forEach((item, index) => {
            let [seriesName, data, width, color] = item;
            this.plotPitchSeries(canvas, seriesName, index, data, width, color);
        });


        //
        // this.plotPitchSeries(canvas, avgOfDefinedPitches, 4, '#aaaaaa');
        // // this.plotPitchSeries(canvas, macleodSeries, 2, '#dd9944');
        // this.plotPitchSeries(canvas, yinSeries, 2, '#5577aa');
        // this.plotPitchSeries(canvas, dynamicWaveletSeries, 2, '#ddccaa');
        // // this.plotPitchSeries(canvas, amdfSeries, 2, '#dd0055');
        // this.plotPitchSeries(canvas, acf2PlusSeries, 2, '#dd00dd');
        // // this.plotPitchSeries(canvas, fftPeakFrequencySeries, 2, '#668888');

        // const yTop = 1.0;
        // const yTop = frameStats.frequencyDomainStats.max;
        // const yBottom = frameStats.timeDomainStats.min;
    }

    private pitchResponses() {
        const p = this.props.audioProcessor.sampleCharacteristicsSeries.sampleCharacteristicsSeries.peekBack();
        if (p === undefined) return <></>;
        return <>
            <div>ACF2PLUS pitch: {p.acf2Plus?.toPrecision(4)} {p.acf2Plus ? noteFromPitch(p.acf2Plus) : ''}</div>
            <div>AMDF pitch: {p.amdf?.toPrecision(4)} {p.amdf ? noteFromPitch(p.amdf) : ''}</div>
            <div>YIN pitch: {p.yin?.toPrecision(4)} {p.yin ? noteFromPitch(p.yin) : ''}</div>
            <div>DynamicWavelet
                pitch: {p.dynamicWavelet?.toPrecision(4)} {p.dynamicWavelet ? noteFromPitch(p.dynamicWavelet) : ''}</div>
            <div>Macleod
                pitch: {p.macleod?.freq.toPrecision(4)} {p.macleod ? (p.macleod?.probability * 100).toPrecision(2) : ''}% {p.macleod ? noteFromPitch(p.macleod.freq) : ''}</div>
            <div>FFT
                max: {p.fftPeakFrequency?.toPrecision(4)} {p.fftPeakFrequency ? noteFromPitch(p.fftPeakFrequency) : ''}</div>
        </>
    }

}

export default AudioVisualiser;
