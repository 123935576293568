import React from "react";
import JumpGame from "./jumpGame";

interface GameVisProps {
    game: JumpGame,
    height: number,
    width: number,
}

interface GameVisState {
}

class GameVis extends React.Component<GameVisProps, GameVisState> {
    private gameCanvas: React.RefObject<HTMLCanvasElement>;
    constructor(props: GameVisProps) {
        super(props);
        this.gameCanvas = React.createRef();
    }

    public draw() {

        const canvas = this.gameCanvas.current;
        if (canvas) {
            const context = canvas.getContext('2d');
            if (!context) {
                console.error(`Can't get canvas.getContext`);
                return;
            }

            // this.props.game.visibleObjects().forEach(o => o.draw())
        }
    }

    render(): React.ReactNode {
        return <canvas ref={this.gameCanvas}
                className={'game-canvas'}
                width={this.props.width}
                height={this.props.height}/>
    }


}


export default GameVis;